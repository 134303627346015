import React, { useState } from 'react';
import {
    ErrorContainer,
    TopLine,
    MainError,
    ErrorContent,
    Description,
    ErrorImage,
    Button,
} from "./ErrorBoundaryHandlerElements";
import { MainContainer } from "../Styles/GlobalElements";
import IMAGES from "../../../constants/images";
import LazyLoadImage from "../LazyLoadImage/index";

const Navbar = React.lazy(() => import('../../Navbar'));
const Sidebar = React.lazy(() => import('../../Sidebar'));

const ErrorBoundaryHandler = ({ error, resetErrorBoundary }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    return (
        <ErrorContainer >
            <Navbar />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <MainContainer>
                <MainError>
                    <ErrorContent>
                        <TopLine>Oops!</TopLine>
                        <Description>
                            The link you followed may be broken,
                            or the page may have been removed.
                        </Description>
                        <Button to="/">Return to Home</Button>
                    </ErrorContent>
                    <ErrorImage>
                        <LazyLoadImage src={IMAGES.common.errorLogo} alt=''></LazyLoadImage>
                    </ErrorImage>
                </MainError>
            </MainContainer>
        </ErrorContainer >
    );
};
export default ErrorBoundaryHandler;