import './App.css';
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from './components/Common/Loader';

const Home = React.lazy(() => import('./pages'));
const ISPO = React.lazy(() => import('./pages/ispoinfo'));
const ContactUsPage = React.lazy(() => import('./pages/contactus'));
const NFTS = React.lazy(() => import('./pages/nfts'));
const CSWAPScanPage = React.lazy(() => import('./pages/cswapscan'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/ispo" element={<ISPO />} exact />
          <Route path="/nfts" element={<NFTS />} exact />
          <Route path="/contactus" element={<ContactUsPage />} exact />
          <Route path="/scan" element={<CSWAPScanPage />} exact />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
