import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga4";
import ErrorBoundaryHandler from "./components/Common/ErrorBoundaryHandler";
import { ErrorBoundary } from 'react-error-boundary';

ReactGA.initialize('G-NZHKZV5F09');

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryHandler}
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);