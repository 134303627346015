import styled from 'styled-components';

export const MainContainer = styled.div` 
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1200px;
    }
`

export const MainDiv = styled.div` 
    background: ${(props) => props.bgColor};
`