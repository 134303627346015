import React from "react";
import IMAGES from "../../../constants/images";
import LazyLoadImage from "../../Common/LazyLoadImage/index";
import { LoaderContainer } from "./LoaderElements";

const Loader = (props) => {
  /**
   * Loader components and props
   */
  return (
    <LoaderContainer>
      <LazyLoadImage src={IMAGES.common.loader} style={{ textAlign: 'center', position: 'absolute', display: 'block', left: '0', right: '0', margin: '0 auto', top: '50%', transform: 'translateY(-50%)', maxWidth: '100px' }} />
    </LoaderContainer>
  );
};
export default Loader;