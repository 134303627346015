import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom';

export const ErrorContainer = styled.div`
    padding-top: 97px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 991px) {
        padding-top: 85px;
    }
    @media screen and (max-width: 575px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

export const TopLine = styled.h1`
    font-weight: 600;
    font-size: 110px;
    line-height: 130px;
    color: #fff;

    @media screen and (max-width: 1199px) {
        font-size: 90px;
        line-height: 105px;
    }
    @media screen and (max-width: 991px) {
        font-size: 72px;
        line-height: 85px;
    }
    @media screen and (max-width: 767px) {
        font-size: 52px;
        line-height: 65px;
    }
    @media screen and (max-width: 575px) {
        font-size: 42px;
        line-height: 55px;
    }
`;

export const MainError = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 0 auto;
    max-width: 70%;
    @media screen and (max-width: 991px) {
        max-width: 100%;
    }
    @media screen and (max-width: 575px) {
        flex-direction: column-reverse;
        text-align: center;
        gap: 20px;
    }
`;

export const ErrorContent = styled.div`
    flex: 0 0 60%;
    max-width: 60%;
    @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

export const Description = styled.p`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 30px;
    max-width: 480px;
    @media screen and (max-width: 1199px) {
        font-size: 20px;
        line-height: 26px;
    }
    @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const ErrorImage = styled.div`
    flex: 0 0 40%;
    text-align: center;
    max-width: 40%;
    > div {
        img {
            max-width: 100%;
            @media screen and (max-width: 575px) {
                max-width: 250px;
            }
        }
    }
    @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

export const Button = styled(LinkR)`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: linear-gradient(90.67deg, #2AEE32 0.58%, #2AEE90 51.54%, #2AEEE2 99.42%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #000;
    padding: 9px 20px;
    width: 197px;
    text-decoration: none;
    @media (max-width: 1199px) {
        font-size: 16px;
        line-height: 22px;
        padding: 9px 15px;
        width: 170px;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;