import React from "react";

const LazyLoadImage = (props) => {
  /**
   * Lazy load image components and props
   */
  return (
    <div className="LazyLoad is-visible">
      <img className={props.className} onClick={props.onClick} src={props.src} style={props.style} alt={props.alt} title={props.title} />
    </div>
  );
};
export default LazyLoadImage;